<template>
  <!--员工管理-->
  <div style="padding: 20px;">
    <div v-if="showEdit == false && showNewSalaryEdit == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="请输入关键词"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="状态:">
                    <el-select
                        clearable
                        v-model="where.status"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.status"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}humanResources:employeeManagement:save`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">新增员工</span>
                </el-button>
                <!--<el-button class="custom-button" style="background: #7958C7!important;border-color: #7958C7!important;color: #FFFFFF!important;" @click="openshownewSalary()" v-auths="[`${$config.uniquePrefix}finance:capacityToBePaid:uploading`]">-->
                <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                <!--  <i class="el-icon-tianjia" />-->
                <!--  <span style="vertical-align: middle">新建薪酬</span>-->
                <!--</el-button>-->
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <template v-slot:status= "{ scope }">
                <div v-for="(item) in dict.type.status">
                  <span v-if="scope.row.status == parseInt(item.value)">{{item.label}}</span>
                </div>
              </template>


              <template v-slot:dept_name= "{ scope }">
                <span v-for="(item) in scope.row.dept_name">
                  <span style="margin-left: 10px;">{{item}}</span>
                </span>
              </template>

              <template v-slot:roles_name= "{ scope }">
                <span v-for="(item) in scope.row.roles_name">
                  <span style="margin-left: 10px;">{{item}}</span>
                </span>
              </template>


              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <div  v-if="scope.row.is_boss != true">
                  <el-link :underline="false" type="warning" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}humanResources:employeeManagement:put`]">编辑</el-link>
                  <el-link :underline="false" type="danger" @click="EditStatus('1',scope.row)" v-if="scope.row.status == 0" v-auths="[`${$config.uniquePrefix}humanResources:employeeManagement:status`]">停用</el-link>
                  <el-link :underline="false" type="success" @click="EditStatus('0',scope.row)" v-auths="[`${$config.uniquePrefix}humanResources:employeeManagement:status`]" v-else>启用</el-link>
                  <el-link  :underline="false" @click="del(scope.row)" v-auths="[`${$config.uniquePrefix}humanResources:employeeManagement:delete`]">删除</el-link>
                </div>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--员工编辑模块组件-->
    <Edit v-if="showEdit == true" :data="showContent" :dict="dict" @showEdit="showedit" @done="getList"/>

    <!--薪酬编辑模块组件-->
    <newSalary-edit v-if="showNewSalaryEdit == true" :dict="dict" @showNewSalary="shownewSalary" @done="getList"/>

  </div>
</template>

<script>
//引入员工编辑组件
import Edit from '../edit/index.vue'
//引入薪酬编辑组件
import NewSalaryEdit from '../newSalary/index.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {getstaff, setstatus, staffdelete} from "@/api/humanResources";
import Vue from "vue";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    Edit,
    NewSalaryEdit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "登录账号",
          // type: "index",
          // width: "70"
          prop: "account",
          isShow: true
        },
        {
          label: "姓名",
          prop: "realname",
          isShow: true
        },
        {
          label: "电话",
          prop: "phone",
          isShow: true
        },
        {
          label: "创建时间",
          prop: "create_time",
          width: '160',
          isShow: true
        },
        // {
        //   label: "工号/坐席号",
        //   prop: "seat_cards",
        //   isShow: true
        // },
        {
          label: "所在部门",
          prop: "dept_name",
          slot: 'dept_name',
          isShow: true
        },
        {
          label: "角色",
          prop: "roles_name",
          slot:'roles_name',
          isShow: true
        },
        {
          label: "在线状态",
          prop: "status",
          slot:'status',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,
      //员工编辑
      showEdit:false,
      showContent:null,

      //薪酬编辑
      showNewSalaryEdit:false,

      //字典
      dict: {
        type:{}
      },

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '人力字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });
    console.log(this.dict)

    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getstaff(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //点击新增员工和详情员工
    openEdit(row){
      this.showContent = row;
      this.showEdit = true;
    },
    //接收编辑子组件传值
    showedit(value){
      this.showEdit = value;
    },

    //点击新增薪酬按钮
    openshownewSalary(){
      this.showNewSalaryEdit = true;
    },
    //薪酬子组件传递的值
    shownewSalary(value){
      this.showNewSalaryEdit = value;
    },

    // 状态修改
    EditStatus(value,row){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        let where = {
          status: value
        }
        setstatus(row.id, where).then(res => {
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.success(res.msg);
          }
        }).catch(e => {
          that.$message.error(e.msg)
        })
      }, "确定", 'warning');
    },

    // 删除
    del(row){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        staffdelete(row.id).then(res => {
          if(res.code === 200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.success(res.msg);
          }
        }).catch(e => {
          that.$message.error(e.msg)
        })
      }, "确定", 'warning');
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
